import * as React from 'react'

import { i18n, useTheme } from '@thg-commerce/enterprise-core'
import { mq, spacing, styled } from '@thg-commerce/enterprise-theme'
import { Carousel } from '@thg-commerce/gravity-patterns'
import {
  AmpCarouselProps,
  CarouselButtonPlacement,
} from '@thg-commerce/gravity-patterns/Carousel/types'

import { Banner, BannerProps } from './Banner'
import { BANNER_MAX_SIZE } from './styles'

export interface BannerSliderProps {
  banners: BannerProps[]
  containBanner?: boolean
  autoPlay?: boolean
  widgetIndex: number
}

const Container = styled.div<{
  containBanner?: boolean
}>`
  margin: 0 auto ${spacing(4)};

  max-width: ${(props) =>
    props.containBanner
      ? props.theme.site.siteWidth
      : BANNER_MAX_SIZE.xs.width};

  ${(props) => mq(props.theme.breakpointUtils.map, 'sm')} {
    max-width: ${(props) =>
      props.containBanner
        ? props.theme.site.siteWidth
        : BANNER_MAX_SIZE.sm.width};
  }

  ${(props) => mq(props.theme.breakpointUtils.map, 'lg')} {
    max-width: ${(props) =>
      props.containBanner
        ? props.theme.site.siteWidth
        : BANNER_MAX_SIZE.lg.width};
  }
`

export const BannerSlider = (props: BannerSliderProps) => {
  const theme = useTheme()

  const carouselText = {
    leftScrollLabel: i18n('carousel.controls.left.arialabel'),
    rightScrollLabel: i18n('carousel.controls.right.arialabel'),
    playPauseLabel: i18n('carousel.controls.playpause.arialabel'),
  }

  const banners = React.useMemo(
    () =>
      props.banners.map((bannerProps, index) => (
        <Banner
          {...bannerProps}
          lazy={props.widgetIndex > 0}
          marginBottom="0px"
          key={index}
        />
      )),
    [props.banners, props.widgetIndex],
  )

  const ampProps: AmpCarouselProps = {
    height: 500,
    width: 300,
    type: 'slides',
    role: 'region',
  }

  return (
    <Container containBanner={props.containBanner}>
      <Carousel
        items={banners}
        isAmp={false}
        ampProps={ampProps}
        i18n={carouselText}
        indicatorStyle="letterbox"
        itemsPerSlide={1}
        autoPlay={props.autoPlay}
        hideControls={false}
        buttonPlacement={CarouselButtonPlacement.Split}
        controlSize={theme.patterns.carousel.controls?.size}
        swipeable={true}
        hideSlidePreview
        shrinkable={true}
        fullWidthCarousel={theme.patterns?.carousel?.fullWidthCarousel}
      />
    </Container>
  )
}
